<template>
  <v-container>
    <v-alert
      :value="exportDisable"
      colored-border
      dismissible
      color="primary lighten-1"
      border="top"
      icon="mdi-check"
      transition="scale-transition"
      elevation="2"
      prominent
    >
      🤾🏻‍♀️ 🤸🏻‍♂️ 🏌🏻‍♀️ 🤾🏻‍♂️ Your export is currently processing. Once the export is
      completed 🏁, you will receive a notification via Slack under
      <code>#admindashboard</code> channel 📢.
    </v-alert>
    <v-card class="mx-auto my-12" max-width="500" elevation="2">
      <v-card-title>Export Active Users</v-card-title>
      <v-card-subtitle
        >Active users are users that have submitted data in the app (quiz, task,
        profiling).</v-card-subtitle
      >
      <v-card-text>
        <v-divider class="mx-4 my-4"></v-divider>
        <h3>Select Range</h3>
        <v-chip-group
          v-model="selection"
          active-class="deep-purple accent-4 white--text"
          column
        >
          <v-chip filter out v-for="tag in tags" :key="tag" :value="tag">
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </v-card-text>

      <v-card-actions>
        <v-btn color="deep-purple lighten-2" text @click="exportUsers">
          Start Export
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>


<script>
import RestResource from "@/services/dataServiceMobile.js";

const service = new RestResource();
export default {
  data() {
    return {
      selection: "1 Month",
      tags: ["1 Month", "3 Months", "6 Months"],
      exportDisable: false,
    };
  },
  methods: {
    exportUsers() {
      let range = this.selection == "1 Month" ? 1 : this.selection == "3 Months" ? 3 : 6;
      service.exportActiveUsersByActivity({ exportDuration: range })
      this.exportDisable = true;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
  },
};
</script>
